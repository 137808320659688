<template>
    <v-snackbar
      v-model="snack"
      :vertical="true"
      multi-line
      timeout="10000"
    >
      <div>You have {{booksPlaying.length}} favorited players, in this event.</div>
      <template v-slot:action="{ attrs }">
      <div class="text-right">

        <v-btn
          color="success"
          text
          @click="showMe"
        >
          Show me
          <v-icon class="ml-3">fas fa-caret-right</v-icon>
        </v-btn>
        <br>
        <v-btn
          color="error"
          text
          v-bind="attrs"
          @click.stop="snack = false"
        >
          Close
          <v-icon class="ml-3" small>fas fa-times</v-icon>
        </v-btn>
        </div>
      </template>
    </v-snackbar>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      snack: false
    }
  },
  computed: {
    ...mapGetters(['user', 'tournament', 'view']),
    booksPlaying () {
      return this.tournament.activePlayers.filter(f => f.isBookmarked(this.user))
    }
  },
  methods: {
    showMe () {
      this.snack = false
      this.$router.push(
        {
          name: 'tournament-teams',
          params: { tournamentId: this.tournament.id },
          query: { bookmarks: true }
        }
      )
    }
  },
  mounted () {
    this.snack = this.booksPlaying.length > 0
  }
}
</script>
